import React, { Component } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/main.less";
import "./js/floating-bg-6";
import ROUTES from "./config/routes";
import Views from "./Views";
import OnPageNavigation from "./components/OnPageNavigation";
import MainNav from "./components/MainNav";
import SiteFooter from "./components/SiteFooter";
import { init, getPageData } from "./state/firebase";
import { AuthContextProvider } from "./state/auth";

class App extends Component {
  componentDidMount() {
    init();
  }

  componentWillUnmount() {
    window.dispatchEvent(new CustomEvent("teardown"));
  }

  render() {
    return (
      <AuthContextProvider>
        <BrowserRouter>
          <OnPageNavigation />
          <div className="container-fluid">
            <MainNav />
            <Routes>
              <Route
                path={ROUTES.ROOT}
                element={<Views.Homepage />}
                errorElement={<Views.Errorpage />}
              />
              <Route path={ROUTES.IRL} element={<Views.IRLPage />} />
              <Route path={ROUTES.ONLINE} element={<Views.LinksPage />} />
              <Route path={ROUTES.WORK} element={<Views.WorkPage />} />
              <Route path={ROUTES.EXPERIENCE} element={<Views.WorkPage />} />
              <Route path="*" element={<Views.Errorpage />} />
            </Routes>
          </div>
          <SiteFooter />
        </BrowserRouter>
      </AuthContextProvider>
    );
  }
}

export default App;
