import Homepage from './Homepage';
import LinksPage from './LinksPage';
import WorkPage from './WorkPage';
import IRLPage from './IRLPage';
import Errorpage from './Errorpage';

export default {
  Homepage,
  LinksPage,
  WorkPage,
  IRLPage,
  Errorpage,
};
