import React, { createContext, useReducer, useEffect } from "react";
import { EMAIL, LOCAL_LOGIN, LOGIN } from "../constants";

import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  Auth,
  browserSessionPersistence,
  signOut,
  Unsubscribe,
} from "firebase/auth";
import { FirebaseApp } from "firebase/app";
import routes from "../config/routes";

const actionCodeSettings = {
  url: location.hostname === "localhost" ? LOCAL_LOGIN : LOGIN,
  handleCodeInApp: true,
};

type AuthStatus = "none" | "sendlink" | "signedin" | "signedout";
const initialAuthState: { user: any; status: AuthStatus } = {
  user: null,
  status: "none",
};
var auth: Auth;

export function initAuth(app: FirebaseApp) {
  auth = getAuth(app);
  auth.setPersistence(browserSessionPersistence);
}

export const AuthContext = createContext(initialAuthState);
export const AuthDispatchContext = createContext<React.Dispatch<Action> | null>(
  null
);

export function AuthContextProvider({ children }) {
  const [authState, dispatch] = useReducer(loginReducer, initialAuthState);

  useEffect(() => {
    doSignin(dispatch);
  }, [authState]);

  return (
    <AuthContext.Provider value={authState}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthContext.Provider>
  );
}

export function sendLoginLink() {
  return sendSignInLinkToEmail(auth, EMAIL, actionCodeSettings)
    .then(() => {
      window.localStorage.setItem("emailForSignIn", EMAIL);
      return true;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

export function signOutUser(dispatch: React.Dispatch<Action>) {
  return signOut(auth)
    .then(() => {
      // Sign-out successful.
      dispatch({ type: "signout", data: null });
      return true;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}

type Action = {
  type: string;
  data: any;
};

function loginReducer(authState, action: Action) {
  switch (action.type) {
    case "sendlink": {
      return { ...authState, status: "sendlink" };
    }
    case "signin": {
      return { ...authState, user: action.data, status: "signedin" };
    }
    case "signout": {
      return { ...authState, user: null, status: "signedout" };
    }
    default: {
      throw new Error("unknown action: " + action.type);
    }
  }
}

function bounceOutOfLogin() {
  window.location.replace(routes.ROOT);
}
let subscriber: Unsubscribe | null = null;
export function doSignin(dispatch: React.Dispatch<Action>) {
  if (auth?.currentUser) {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      bounceOutOfLogin();
    }
    return;
  }

  if (subscriber) {
    subscriber();
    subscriber = null;
  }
  subscriber = auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch({
        type: "signin",
        data: auth.currentUser,
      });
    }
  });

  if (isSignInWithEmailLink(auth, window.location.href)) {
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      return;
    }

    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        window.localStorage.removeItem("emailForSignIn");

        dispatch({
          type: "signin",
          data: result.user,
        });

        bounceOutOfLogin();
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
