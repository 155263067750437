// const $ = require('jquery');
const { mergeRight, path } = require("ramda");

import * as THREE from "three";
window.THREE = THREE;

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader.js";
import { LuminosityHighPassShader } from "three/examples/jsm/shaders/LuminosityHighPassShader.js";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import {
  SMAAEdgesShader,
  SMAAWeightsShader,
  SMAABlendShader,
} from "three/examples/jsm/shaders/SMAAShader.js";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass.js";
import { MaskPass } from "three/examples/jsm/postprocessing/MaskPass.js";

window.THREE.EffectComposer = EffectComposer;
window.THREE.RenderPass = RenderPass;
window.THREE.ShaderPass = ShaderPass;
window.THREE.CopyShader = CopyShader;
window.THREE.LuminosityHighPassShader = LuminosityHighPassShader;
window.THREE.UnrealBloomPass = UnrealBloomPass;
window.THREE.SMAAEdgesShader = SMAAEdgesShader;
window.THREE.SMAAWeightsShader = SMAAWeightsShader;
window.THREE.SMAABlendShader = SMAABlendShader;
window.THREE.SMAAPass = SMAAPass;
window.THREE.MaskPass = MaskPass;

import TweenMax from "gsap";

const NEAR = 0.1;
const FAR = 9000;
const WIDTH = 64;
const keys = ["x", "y", "z"];
const dirs = [1, -1];

let container, controls;
let camera, scene, renderer, geometry, i, h, color, birdMesh;
let mouseX = 0,
  mouseY = 0,
  time,
  last = performance.now();

let winInnerHeight = () =>
  window.innerHeight > 900 ? window.innerHeight : 900;
let winInnerWidth = () => (window.innerWidth > 900 ? window.innerWidth : 900);
let windowHalfX = winInnerWidth() / 2;
let windowHalfY = winInnerHeight() / 2;
let BOUNDS = 800,
  BOUNDS_HALF = BOUNDS / 2;

let cameraYMax = 100;
let cameraYMin = -1000;

const lockToSize = (value, lockSize) => {
  return Math.round(value / lockSize) * lockSize;
};

export default class Boxes {
  constructor() {
    let context = this;
    this.boxesCollection = [];
    var handlers = {
      resize: this._handleResize.bind(this),
      navigation: this._onNavigation.bind(this),
      teardown: () => {
        Object.entries(handlers).forEach(([key, handle]) => {
          window.removeEventListener(key, handle);
        });
      },
    };

    window.addEventListener("resize", handlers.resize);
    window.addEventListener("navigation", handlers.navigation);
    window.addEventListener("teardown", handlers.teardown);

    context.bindHandler("_render", "_handleResize");

    context.setup3D();
    context.createScene();

    context.start();
    setTimeout(() => {
      this._handleResize();
    }, 100);
  }

  start() {
    let loop = () => {
      requestAnimationFrame(loop);
      this._render();
    };
    //requestAnimationFrame(this.animate.bind(this));
    requestAnimationFrame(loop);
  }

  bindHandler(...methods) {
    methods.forEach((method) => (this[method] = this[method].bind(this)));
  }

  setup3D() {
    renderer = this.renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setClearColor(0x979797, 1);
    renderer.setSize(winInnerWidth(), winInnerHeight());
    renderer.setPixelRatio(1);

    // $(document.body).prepend(renderer.domElement);

    document.body.insertBefore(
      renderer.domElement,
      document.body.childNodes[0]
    );

    renderer.domElement.style.position = "fixed";
    renderer.domElement.style.top = 0;
    renderer.domElement.style.left = 0;

    this.setupScene();
    this.setupControls();
    this.setupBoxes();
  }

  setupScene() {
    scene = this.scene = new THREE.Scene();
    scene.add(new THREE.AmbientLight(0xeeeeee));

    this.scene.fog = new THREE.Fog(0x999999, 200, 1200);
    this.camera = new THREE.PerspectiveCamera(
      75,
      winInnerWidth() / winInnerHeight(),
      NEAR,
      FAR
    );

    this.light = new THREE.PointLight(0xffffff, 0.5, 2000);
    this.light.position.set(winInnerWidth() / winInnerHeight(), 100, 500, -200);

    scene.add(this.light);

    camera = this.camera;
    camera.position.y = 100;
    camera.position.z = 500;
  }

  setupControls() {
    let camera = this.camera;
    let light = this.light;

    // let $win = $(window);
    let scrollRAF = () => {
      camera.position.y = -window.scrollY / 2;
      light.position.set(
        camera.position.x,
        camera.position.y,
        camera.position.z - 300
      );
      requestAnimationFrame(scrollRAF);
    };

    scrollRAF();
  }

  setupBoxes() {
    let randomPos = () => Math.round(Math.random() * 40 - 20) * 100;
    const X_SPREAD = 1000;
    const SQUARE_SIZE = 100;

    return Promise.resolve()
      .then(() => {
        let sphereGeo = new THREE.SphereBufferGeometry(1200);
        let sphereMaterial = new THREE.MeshPhongMaterial({
          specular: 0x111111,
          shininess: 100,
          color: 0x000000,
          side: THREE.BackSide,
        });

        let sphereObj = new THREE.Mesh(sphereGeo, sphereMaterial);
        scene.add(sphereObj);
        this.bigSphere = sphereObj;

        this.boxGroup = new THREE.Group();
        this.boxGroup.position.z = -500;
        scene.add(this.boxGroup);

        let material1 = new THREE.MeshPhongMaterial({
          specular: 0x3763a8,
          shininess: 30,
          color: 0xeeeeee,
        });

        let material2 = new THREE.MeshPhongMaterial({
          specular: 0x3763a8,
          shininess: 45,
          color: 0xbbbbbb,
        });

        let material3 = new THREE.MeshPhongMaterial({
          specular: 0x787811,
          shininess: 15,
          color: 0x3763a8,
        });

        let lastBoundingBox = null;
        let geometry = new THREE.BoxBufferGeometry(
          SQUARE_SIZE,
          SQUARE_SIZE,
          SQUARE_SIZE
        );

        for (let i = 0; i < 500; i++) {
          let mat =
            Math.random() < 0.15
              ? material3
              : Math.random() > 0.65
              ? material1
              : material2;
          let boxObject = new THREE.Mesh(geometry, mat);
          boxObject.position.x = lockToSize(
            Math.random() * X_SPREAD - X_SPREAD / 2,
            SQUARE_SIZE
          );
          boxObject.position.y = lockToSize(1000 + i * -10, SQUARE_SIZE);
          boxObject.position.z = lockToSize(
            Math.random() * X_SPREAD - X_SPREAD / 2,
            SQUARE_SIZE
          );
          // boxObject.rotation.y = 0.25 * Math.PI;
          // boxObject.rotation.x = 0.25 * Math.PI;
          // boxObject.castShadow = true;
          // boxObject.receiveShadow = true;
          // boxObject.rotation.y = Math.random() * 2 * Math.PI;
          // boxObject.rotation.z = Math.random() * 2 * Math.PI;
          this.boxGroup.add(boxObject);

          this.boxesCollection.push(boxObject);

          if (boxObject.position.y < cameraYMin + 500) {
            cameraYMin = boxObject.position.y + 500;
          }
        }

        this.randomBoxTwirl();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  randomBoxTwirl() {
    const duration = 0.4;
    const dirRotation = pluckRandom([1, -1]);
    const dirPosition = pluckRandom([1, -1]);
    const keyRotation = pluckRandom(keys);
    const keyPostion = pluckRandom(keys);

    const box = pluckRandom(this.boxesCollection);

    const newValueRotation =
      box.rotation[keyRotation] + (Math.PI / 2) * dirRotation;
    const newValuePosition = box.position[keyPostion] + 100 * dirPosition;

    TweenMax.to(box.rotation, {
      [keyRotation]: newValueRotation,
      duration,
      ease: "back.out",
    });

    TweenMax.to(box.position, {
      [keyPostion]: newValuePosition,
      duration,
      ease: "back.out",
    });

    setTimeout(() => {
      this.randomBoxTwirl();
    }, Math.floor((duration * 1000) / 4));
  }

  createScene() {
    scene = this.scene;
    const renderScene = new THREE.RenderPass(scene, camera);
    const bloomPass = new THREE.UnrealBloomPass(
      new THREE.Vector2(winInnerWidth(), winInnerHeight()),
      1.5,
      0.4,
      0.85
    );
    bloomPass.threshold = 0.96;
    bloomPass.strength = 0.21;
    bloomPass.radius = 0.87;
    const aapass = new THREE.SMAAPass(
      winInnerWidth() * renderer.getPixelRatio(),
      winInnerHeight() * renderer.getPixelRatio()
    );
    aapass.sampleLevel = 3;
    const composer = new THREE.EffectComposer(renderer);
    composer.addPass(renderScene);
    composer.addPass(aapass);
    composer.addPass(bloomPass);

    this.composer = composer;
  }

  _render(timestamp) {
    const scene = this.scene;
    const camera = this.camera;
    const renderer = this.renderer;

    // if ( camera.position.y < cameraYMin ) {
    //   camera.translateY( Math.ceil((cameraYMin - camera.position.y) / 4) + 1 );
    // }
    // if ( camera.position.y > cameraYMax ) {
    //   camera.translateY( Math.floor((cameraYMax - camera.position.y) / 4) - 1 );
    // }

    this.light.position.set(
      camera.position.x,
      camera.position.y,
      camera.position.z
    );

    this.bigSphere.position.set(
      camera.position.x,
      camera.position.y,
      camera.position.z - 600
    );

    //renderer.render(scene, camera);
    this.composer.render();
  }

  animate() {
    requestAnimationFrame(this.animate.bind(this));
    //controls.update();
  }

  _handleResize() {
    if (!this.resizer) {
      const renderer = this.renderer;
      const camera = this.camera;
      const width = winInnerWidth();
      const height = winInnerHeight();

      this.resizer = setTimeout(() => {
        camera.aspect =
          width / height > height / width ? width / height : height / width;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height);
        this.resizer = null;
      }, 100);
    }
  }

  _onNavigation(event) {
    if (this.boxGroup) {
      const dist = 0.5 * path(["detail", "direction"], event);
      TweenMax.to(this.boxGroup.rotation, 2, {
        y: this.boxGroup.rotation.y + Math.PI * dist,
      });
    }
  }
}

function pluckRandom(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function init_bg() {
  return new Boxes();
}

document.addEventListener("DOMContentLoaded", (event) => {
  init_bg();
});
