import { initializeApp, FirebaseApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  QueryDocumentSnapshot,
  DocumentData,
  Firestore,
  DocumentSnapshot,
  CollectionReference,
} from "firebase/firestore";

import { initAuth } from "./auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDF3ds3xhvdv3_g9PE_tGlqR6uwcoqImHM",
  authDomain: "mindandcode-website.firebaseapp.com",
  databaseURL: "https://mindandcode-website.firebaseio.com",
  projectId: "mindandcode-website",
  storageBucket: "mindandcode-website.appspot.com",
  messagingSenderId: "771750143692",
  appId: "1:771750143692:web:8511384b8ac19d8f600469",
};

var app: FirebaseApp;
var db: Firestore;

const pathNameMap = (pathName = "") =>
  pathName === "/" ? ["root"] : pathName.split("/");

class PageDataStore {
  content: Record<string, QueryDocumentSnapshot<DocumentData>> = {};
  async loadContent(db) {
    const pageCollection = collection(db, "content");
    const pageSnapshot = await getDocs(pageCollection);
    pageSnapshot.docs.forEach((doc) => {
      this.content[doc.id] = doc;
    });
  }

  getPageByID(id: string): QueryDocumentSnapshot<DocumentData> | undefined {
    return this.content[id];
  }
}

let pageDataStore: PageDataStore = new PageDataStore();

export function init() {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  db = getFirestore(app);
  initAuth(app);
  pageDataStore.loadContent(db);
}

export async function getPageData(
  pathName = "/"
): Promise<DocumentData | null> {
  const docSnapshot = await getPageDoc(pathName);
  if (docSnapshot) {
    return docSnapshot.data();
  }
  return null;
}

export async function pageData(pageDataLocation) {
  const documentSnapshot = await getPageDoc(pageDataLocation);
  return documentSnapshot?.data();
}

export async function getPageDoc(
  pathName: string
): Promise<QueryDocumentSnapshot<DocumentData> | undefined> {
  const pathNameFilter: string[] = pathNameMap(pathName);
  const docIDByPathName = pathNameFilter[pathNameFilter.length - 1];
  let docRef = pageDataStore.getPageByID(docIDByPathName);
  if (!docRef) {
    await pageDataStore.loadContent(db);
  }

  return pageDataStore.getPageByID(docIDByPathName);
}

export async function patchDoc(
  pageDataLocation: string,
  data: Record<string, any>
) {
  const pageDocSnapshot = await getPageDoc(pageDataLocation);
  if (!pageDocSnapshot?.ref) {
    throw new Error("Page document does not exist");
  }
  await setDoc(pageDocSnapshot.ref, data, { merge: true });
  await pageDataStore.loadContent(db);

  return pageDataStore.getPageByID(pageDataLocation);
}
