import React from "react";
import imageMap from "../utils/irlImages";

function include_instagram(instagramPhotoID) {
  return (
    <div className="col-md-6 col-sm-12 ig-wrap">
      <div className="container">
        <a
          href={`https://instagram.com/p/${instagramPhotoID}`}
          rel="noopener"
          target="_blank"
        >
          <img src={imageMap[instagramPhotoID]} alt="" />
        </a>
      </div>
    </div>
  );
}

export default function IRLPage(props) {
  return (
    <div
      id="aboutPage"
      className="main-wrapper add-floating-bg add-floating-bg-1"
      data-editor-tags="new,dashboard"
    >
      <article className="container see-through">
        <header className="deep-inset-content">
          <h1 className="text-center">Keegan, in real life.</h1>
        </header>
        <section className="deep-inset-content clearfix">
          <h3>
            I figure that a personal story is best told through images, so
            here&rsquo;some instagram shots I&rsquo;ve taken. This is me:
          </h3>
          {include_instagram("7T1zInvd0t")}
          {include_instagram("rvtFpaPd7P")}
        </section>
        <section className="deep-inset-content clearfix">
          <h3>I like to experiment with technology.</h3>
          {include_instagram("rvtQycvd7i")}
          {include_instagram("rDPXExPd8i")}
          {include_instagram("wxLPGOvd46")}
          {include_instagram("pK7jwZvd9w")}
        </section>
        <section className="deep-inset-content clearfix">
          <h3>
            I live in New Orleans, and I&rsquo;m rather fond of this city.
          </h3>
          {include_instagram("s7jyWxPd8d")}
          {include_instagram("qnYTU2vdw1")}
          {include_instagram("8ZkCPZvd0W")}
          {include_instagram("4vSVauPdzw")}
          {include_instagram("4HaI5iPdwq")}
          {include_instagram("0EmGCbvd7j")}
          {include_instagram("rNbk4cvd5U")}
          {include_instagram("pK7OyMPd9X")}
        </section>
        <section className="deep-inset-content clearfix">
          <h3>I like to ride bikes.</h3>
          {include_instagram("orbPyTvd0T")}
          {include_instagram("tvdodFPd6y")}
          {include_instagram("rJHUHNPd4H")}
          {include_instagram("6_UwSNPd7q")}
          {include_instagram("rA48AxPd9_")}
          {include_instagram("4Asb9ePd7d")}
        </section>
        <section className="deep-inset-content clearfix">
          <h3>I&rsquo;ve got an awesome family.</h3>
          {include_instagram("135knrvd2F")}
          {include_instagram("qCeEpBPdz_")}
          {include_instagram("paZAsdPd9x")}
          {include_instagram("zgs_p0vd1C")}
        </section>
      </article>
    </div>
  );
}
