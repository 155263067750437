export default {
  ROOT: '/',
  LINKS: '/links',
  ONLINE: '/online',
  IRL: '/in-real-life',
  CV: '/cv',
  WORK: '/cv',
  EXPERIENCE: '/my-experience',
  BLOG: {
    ROOT: '/blog',
    POST: '/blog/:postSlug'
  }
};
