import React from 'react';

const Link = ({ href, target = '_blank', children, ...props }) => {
  return (
    <a
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : ''}
      {...props}
    >
      {children}
    </a>
  );
}


export default Link;
