import React, { useContext, useEffect, useState } from "react";
import {
  AuthContext,
  AuthDispatchContext,
  sendLoginLink,
  signOutUser,
} from "../state/auth";

const logoutLinkDisplay = () =>
  window.location.hostname === "localhost" ? "block" : "none";

export default function SiteFooter() {
  const authState = useContext(AuthContext);
  const authDispatch = useContext(AuthDispatchContext);

  const [isLoggedIn, setIsLoggedIn] = useState(!!authState?.user);
  const [isUpdated, setUpdated] = useState(Date.now());

  useEffect(() => {
    setIsLoggedIn(!!authState?.user);
  }, [authState, isUpdated]);

  return (
    <div className="site-footer">
      <footer>
        <p>
          &copy; {new Date().getFullYear()} Keegan Brown, Mind and Code, LLC
        </p>
        {!isLoggedIn ? (
          <p style={{ display: logoutLinkDisplay() }}>
            <a
              href="#"
              onClick={() => {
                sendLoginLink().then((success) => {
                  if (success) {
                    window.alert("Login link sent.");
                    setUpdated(Date.now());
                  }
                });
              }}
            >
              login
            </a>
          </p>
        ) : (
          <p>
            <a
              href="#"
              onClick={() => {
                signOutUser(authDispatch).then((success) => {
                  if (success) {
                    window.alert("user signed out");
                    setUpdated(Date.now());
                  }
                });
              }}
            >
              logout
            </a>
          </p>
        )}
      </footer>
    </div>
  );
}
