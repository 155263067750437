import React, { useEffect, useRef } from "react";
import { Editable } from "../components/Editor";
import { pageData, patchDoc } from "../state/firebase";

export default function WorkPage() {
  const pageDataLocation = window.location.pathname;
  const pageDataLoader = async () => {
    return pageData(pageDataLocation);
  };

  const onSave = (uniqueDataKey, contents) => {
    patchDoc(pageDataLocation, { [uniqueDataKey]: contents.ops })
      .then(() => {
        console.log(`${uniqueDataKey} document updated`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      id="workPage"
      className="main-wrapper add-floating-bg add-floating-bg-1"
    >
      <div className="container">
        <header>
          <h1 className="text-center">Curriculum Vitae</h1>
        </header>
        <section className="deep-inset-content clearfix">
          <Editable
            uniqueDataKey={"professional-experience"}
            pageDataAsync={pageDataLoader}
            onSave={onSave}
          >
            <h2>Professional Experience</h2>
            <h3>
              Engineering Manager <br /> <strong>InVision</strong>
            </h3>
            <p>September 2021 &ndash; February 2023</p>
            <h3>
              Senior Software Engineer <br /> <strong>InVision</strong>
            </h3>
            <p>July 2020 &ndash; September 2021</p>
            <h3>
              Senior Software Engineer <br /> <strong>GE Power</strong>
            </h3>
            <p>October 2019 &ndash; June 2020</p>
            <h3>
              Principal Software Engineer, CTO <br />
              <strong>Story Block</strong>
            </h3>
            <p>March 2018 &ndash; October 2019</p>
            <h3>
              Director of Software Engineering <br />
              <strong>Smashing Boxes</strong>
            </h3>
            <p>February 2017 &ndash; March 2018</p>
            <h3>
              Senior Developer <br /> <strong>Smashing Boxes</strong>
            </h3>
            <p>December 2015 &ndash; February 2017</p>
            <h3>
              Principal Software Engineer, CTO, VP Platform &amp; Technology
              <br />
              <strong>Grok + Banter</strong>
            </h3>
            <p>December 2014 &ndash; October 2015</p>
            <h3>
              Senior Developer <br /> <strong>Peter Mayer Advertising</strong>
            </h3>
            <p>December 2012 &ndash; August 2015</p>
            <h3>
              Developer <br /> <strong>Peter Mayer Advertising</strong>
            </h3>
            <p>December 2010 &ndash; December 2012</p>
            <h3>
              Associate Interactive Developer <br />
              <strong>Peter Mayer Advertising</strong>
            </h3>
            <p>March 2009 &ndash; December 2010</p>
          </Editable>
        </section>
      </div>
      <div className="container">
        <section className="deep-inset-content clearfix">
          <header>
            <h2>Qualifications and Related Expertise</h2>
          </header>
          <Editable
            uniqueDataKey={"programming-technologies"}
            pageDataAsync={pageDataLoader}
            onSave={onSave}
          >
            <h3>Programming Technologies &ndash; High Fluency:</h3>
            <h4>
              JavaScript/TypeScript Programming Experience (including but not
              limited to):
            </h4>
            <p>
              Browser: Angular, Angular.js, React, Redux, RxJS, Ramda, TweenMax
              (GSAP), Canvas API, WebGL, AJAX, HTML5 APIs
            </p>
            <p>
              NodeJS: Express, Ghost, Gulp, ORM/ODM, Web sockets, Native
              Add-ons, API Integration & Development
            </p>
            <h4>PHP Programming Experience (including but not limited to):</h4>
            <ul>
              <li>Wordpress: custom theme and plug-in development</li>
              <li>
                Drupal: Site-wide Malware Disinfection, Ongoing site maintenance
                and configuration
              </li>
              <li>
                Custom Scripting: AJAX endpoints, 3rd Party API Data
                integration, Templating, OAuth Integration
              </li>
            </ul>

            <h3>Programming Technologies &ndash; Variable Experience:</h3>
            <ul>
              <li>
                Ruby &mdash; Ruby on Rails Development, Puma Server
                Configuration, Application Deployment
              </li>
              <li>
                C# &mdash; Umbraco CMS Macro Development and Maintenance,
                Microsoft Kinect Integration
              </li>
              <li>
                Java &mdash; Spring Boot, Processing.org, Microsoft Kinect
                Integration, Exporting to Processing.js
              </li>
              <li>
                C++ &mdash; Emotiv EPOC Hardware Integration, Openframeworks,
                Cinder++, OpenCV, NodeJS Add-ons
              </li>
              <li>
                Bash &mdash; Shell Scripting as needed to support web
                applications
              </li>
            </ul>

            <h3>Document Syntax and Presentation:</h3>
            <p>
              JSX, Angular Templates, HTML5, CSS3, JSON, XML, YML, SVG, Markdown
            </p>

            <h3>Database Experience:</h3>
            <p>MongoDB, MySQL, MSSQL, PostgresSQL, SQLite</p>
          </Editable>
        </section>
      </div>
      <div className="container">
        <section className="deep-inset-content clearfix">
          <header>
            <h3>Relevant Web Development Skills, Knowledge, and Experience:</h3>
          </header>
          <Editable
            uniqueDataKey={"relevant-expertise"}
            pageDataAsync={pageDataLoader}
            onSave={onSave}
          >
            <ul>
              <li>
                Very Familiar with MVC Pattern/Paradigm, Messenger (PubSub), and
                other programming design patterns
              </li>
              <li>Programmatic timeline animation and interaction design</li>
              <li>Web video compression, encoding, codecs and streaming</li>
              <li>
                AJAX, WebSockets, HTML5 History API and other single-page-app
                development methodologies
              </li>
              <li>
                OAuth + 3rd Party API Integration (Facebook, Twitter, Instagram,
                Assembla, et al.)
              </li>
              <li>
                Linux Server Administration (Setup, Configuration, Maintenance,
                Package Installation)
              </li>
              <li>
                Apache and NGINX Web Server Configuration and performance
                optimization (including Mod_Pagespeed)
              </li>
              <li>
                SSL Certificate Installation, Configuration, and Validation
              </li>
              <li>
                Git in a team environment (Branching/Merging/Pull requests,
                deployment scripting with Githooks)
              </li>
              <li>Embedded Hardware Development with Raspberry Pi</li>
              <li>Cross-brower compatibility best practices</li>
              <li>Advanced Javascript debugging techniques</li>
            </ul>
          </Editable>
        </section>
      </div>
      <div className="container">
        <section className="deep-inset-content clearfix">
          <Editable
            uniqueDataKey={"personal-qualifications"}
            pageDataAsync={pageDataLoader}
            onSave={onSave}
          >
            <h2>Education</h2>
            <h3>University of North Florida &mdash; Jacksonville, FL</h3>
            Bachelor of Fine Arts, Multimedia Design, 2005 &ndash; 2008 (Honors)
            <h3>Santa Fe Community College &mdash; Santa Fe, NM</h3>
            Associates of Applied Science, Media Arts, 2003 &ndash; 2005
            <h2>Honors and Awards</h2>
            <h3>
              Advertising Person of the Year 2014 [Interactive Developer
              Category]
            </h3>
            <p>
              The Ad Club of New Orleans &mdash; Nominated and Elected by Peers,
              February 2014.
            </p>
            <h2>Personal Activities</h2>
            <ul>
              <li>Black-belt in Shotokan Karate</li>
              <li>Daily Meditation Practitioner</li>
              <li>Dedicated Road Cyclist</li>
              <li>Neuroscience Enthusiast</li>
              <li>Science Enthusiast</li>
              <li>Generative/Programmatic Artist</li>
            </ul>
          </Editable>
        </section>
      </div>
    </div>
  );
}
