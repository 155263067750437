export default {
  template: 'Homepage',
  content: [
    {
      headline: 'Hi, I&rsquo;m Keegan.',
      body: [
        `I&rsquo;m an experienced software engineer, with experience
        that spans the entire web technology stack.`,
        `I love to develop engaging, interactive web applications that
        push the limits of web technology. I&rsquo;ve developed
        software that lives outside the web too -- like embedded
        applications for the Raspberry Pi. In short: I&rsquo;m in this
        to build amazing stuff, and make a real impact on the
        businesses I work with.`
      ],
      ctaNav: [
        {
          text: 'Learn about my skillset',
          href: '#scrollstop1',
          icon: 'glyphicon-chevron-down'
        },
        {
          text: 'View my Curriculum Vitae',
          href: '/cv',
          icon: 'glyphicon-chevron-right'
        }
      ]
    },
    {
      subhead: 'I love challenges.',
      body: [
        `I get the most out of my work when I&rsquo;m learning
        something new. I&rsquo;m known for tackling projects just
        because they sound hard, interesting, or unique.`,
        `Here&rsquo;s a few examples of the technologies I&rsquo;ve
        picked up for the challenge of it:`
      ],
      externalLinkList: [
        { href: 'https://www.processing.org/', text: 'Processing' },
        { href: 'https://threejs.org/', text: 'Three.js' },
        { href: 'https://www.raspberrypi.org/', text: 'Raspberry Pi' },
        { href: 'https://www.arduino.cc/', text: 'Arduino' },
        { href: 'http://opencv.org/', text: 'OpenCV' },
        {
          href: 'https://github.com/OpenKinect/libfreenect',
          text: 'Open Kinect'
        },
        { href: 'https://libcinder.org/', text: 'Cinder++ (libcinder)' },
        { href: 'https://emotiv.com/epoc.php', text: 'Emotiv EPOC' },
        { href: 'http://developer.choosemuse.com/sdk', text: 'Muse SDK' },
        { href: 'https://www.tensorflow.org/', text: 'Tensorflow' }
      ]
    },
    {
      subhead: `Client side development <br/>
      is my bread and butter.`,
      body: [
        `Don&rsquo;t get me wrong &ndash; I like manipulating,
        traversing, and transforming data and developing REST API
        endpoints as much as the next guy. But I take a special
        satisfaction in creating math-based algorimic animations and
        interactive components. I've hit a few high points in this
        area:`
      ],
      unorderedList: [
        `You want an <strong>HTML5 Canvas</strong> particle engine?
        Let&rsquo;s do it.`,
        `<strong>CSS3 3d</strong> effects? Easy.`,
        `How about <strong>game logic</strong>? Sounds great.`,
        `This image needs to <strong>follow the mouse</strong> around
        like a lost puppy? Consider it done.`,
        `You'd like your whole website to react to the user's scroll?
        Not a problem.`
      ]
    },
    {
      subhead: `I&rsquo;ve never met a backend stack <br />
      that I couldn&rsquo;t get along with.`,
      body: [
        `These days I like to build in JavaScript or TypeScript if I
        have the choice. I find JavaScript and TypeScript to be the
        perfect balance of flexibilty and features -- and if I don't
        find something I need, I can almost always find a community
        package that does.`,
        `Real world programming comes down to efficency for the
        programming team, and I find working in JavaScript and
        TypeScript to be some of the most efficient developer
        experiences out there. Node.JS is my go-to backend for apps
        where I can pick and choose my application stack, but
        I&rsquo;m definitely not limited to it.`,
        ` I&rsquo;ve worked in a host of different environments, from
        <strong>NodeJS</strong>, <strong>PHP</strong>,
        <strong>Ruby (on Rails)</strong>, <strong>C#/.NET</strong>, 
        <strong>Java</strong>, and more. I&rsquo;ve also used and configured
        <strong>Apache</strong>, <strong>NGINX</strong>, and
        <strong>IIS</strong>. I&rsquo;ve even spent time optimizing
        site performance on both <strong>Linux</strong> and
        <strong>Windows</strong> Server Platforms.`
      ]
    },
    {
      subhead: `I've learned a few things along the way`,
      subtext: `<em>But I'm always open to learning more.</em>`,
      unorderedList: [
        `Code is for humans, not computers. Write code that is
        readable, scannable, and easily understandable, as much as
        possible.`,
        `It's important to break code down into small-bite size
        functions. Writing a simple function to do a single thing is
        one of the easiest way to simplify a convoluted programming
        routine.`,
        `Writing smarter code is better than writing code faster.
        Take the time to think code through.`,
        `Functional programming is generally a superior programming
        paradigm than procedural programming. But like many others
        before me, I tend to blend the two as needed.`,
        `Composition is always better than inheritance.`,
        `Writing descriptive function and variable names before
        writing code comments.`,
        `A function or method should always only do one thing.`,
        `If code looks repetitive, then there is a better way to
          write it.`
      ]
    }
  ]
};
