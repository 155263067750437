import { useEffect } from "react";
import TweenMax from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import routes from "../config/routes";
import { useLocation } from "react-router-dom";

TweenMax.registerPlugin(ScrollToPlugin);

const routesLookup = [routes.ROOT, routes.IRL, routes.ONLINE, routes.WORK];
let lastLocation = "";

const useLocationChange = (action) => {
  const location = useLocation();
  useEffect(() => {
    action(location);
  }, [location]);
};

function OnPageNavigation() {
  useLocationChange((location) => {
    window.dispatchEvent(
      new CustomEvent("navigation", {
        detail: {
          direction: findRouteDirection(location, lastLocation),
          location,
        },
      })
    );
    lastLocation = location.pathname;

    TweenMax.set(window, {
      scrollTo: 0,
    });
  });

  return null;
}

function findRouteDirection(location, lastLocation) {
  const prev = routesLookup.indexOf(location.pathname);
  const next = routesLookup.indexOf(lastLocation);
  const value = next - prev;
  if (value === 0) {
    return 0;
  }
  return value / Math.abs(value);
}

export default OnPageNavigation;
