import React from "react";
import Link from "../components/Link";
import gsap from "gsap";

import state from "../state/Homepage";
import { pageData, patchDoc } from "../state/firebase";
import { Editable } from "../components/Editor";

const handleScrollStop = (e) => {
  e.preventDefault();
  gsap.to(window, { duration: 0.75, scrollTo: { y: e.currentTarget.hash } });
};

function getUniqueDataKey(text: string) {
  const temp = document.createElement("p");
  temp.innerHTML = text;
  const parsed = temp.innerText;

  return parsed
    .trim()
    .toLowerCase()
    .replace(/[^a-z ]/g, "")
    .replace(/\s+/g, "-");
}

export default function Homepage() {
  const pageDataLocation = window.location.pathname;
  const pageDataLoader = async () => {
    return pageData(pageDataLocation);
  };

  const onSave = (uniqueDataKey, contents) => {
    patchDoc(pageDataLocation, { [uniqueDataKey]: contents.ops })
      .then(() => {
        console.log(`${uniqueDataKey} document updated`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div
      id="homepageStory"
      className="storybook add-floating-bg add-floating-bg-1"
      data-editor-tags="new,dashboard"
    >
      {state.content.map((storyboxContent, index, contentArr) => {
        const numberOfBoxes = contentArr.length;
        return (
          <div
            className="story story1"
            id={`scrollstop${index}`}
            key={`scrollstop${index}`}
          >
            <section className="container">
              <div className="col-xs-12 col-lg-10 col-lg-offset-1">
                <div className="story-box row">
                  <header>
                    {storyboxContent.headline ? (
                      <h1
                        className=" text-center"
                        dangerouslySetInnerHTML={{
                          __html: storyboxContent.headline,
                        }}
                      ></h1>
                    ) : null}
                    {storyboxContent.subhead ? (
                      <h2
                        className=" text-center"
                        dangerouslySetInnerHTML={{
                          __html: storyboxContent.subhead,
                        }}
                      ></h2>
                    ) : null}
                    {storyboxContent.subtext ? (
                      <p
                        className=" text-center"
                        dangerouslySetInnerHTML={{
                          __html: storyboxContent.subtext,
                        }}
                      ></p>
                    ) : null}
                  </header>
                  <section className="static-section">
                    <Editable
                      uniqueDataKey={`index-${index}-${getUniqueDataKey(
                        storyboxContent.headline ?? storyboxContent.subhead
                      )}`}
                      pageDataAsync={pageDataLoader}
                      onSave={onSave}
                    >
                      {storyboxContent.body
                        ? storyboxContent.body.map((paragraph) => {
                            return (
                              <p
                                key={paragraph}
                                dangerouslySetInnerHTML={{
                                  __html: paragraph,
                                }}
                              ></p>
                            );
                          })
                        : null}
                      {storyboxContent.unorderedList ? (
                        <ul>
                          {storyboxContent.unorderedList.map((listItem) => {
                            return (
                              <li
                                key={listItem}
                                dangerouslySetInnerHTML={{
                                  __html: listItem,
                                }}
                              ></li>
                            );
                          })}
                        </ul>
                      ) : null}
                    </Editable>
                    {storyboxContent.externalLinkList ? (
                      <ul className="list-inline challenge-list">
                        {storyboxContent.externalLinkList.map(
                          (externalLink) => {
                            return (
                              <li key={externalLink.href}>
                                <Link
                                  href={externalLink.href}
                                  className="btn btn-lg btn-success"
                                  target="_blank"
                                >
                                  <span>
                                    <i className="small-glyph glyphicon glyphicon-new-window"></i>
                                    {externalLink.text}
                                  </span>
                                </Link>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    ) : null}
                    {storyboxContent.ctaNav ? (
                      <nav className="cta-nav row">
                        {storyboxContent.ctaNav.map((ctaItem) => {
                          const isPageAnchor = ctaItem.href[0] === "#";
                          return (
                            <a
                              onClick={
                                isPageAnchor ? handleScrollStop : () => {}
                              }
                              key={ctaItem.href}
                              href={ctaItem.href}
                              className={`btn flip-btn btn-lgr ${
                                isPageAnchor ? "scroll-link" : ""
                              }`}
                            >
                              <span>
                                <strong>{ctaItem.text}</strong>
                                <i className={`glyphicon`}></i>
                              </span>
                            </a>
                          );
                        })}
                      </nav>
                    ) : null}
                  </section>
                  {index > 0 ? (
                    <a
                      onClick={(e) => {
                        handleScrollStop(e);
                      }}
                      href={`#scrollstop${index - 1}`}
                      className="scroll-link link-up"
                    >
                      <i className="glyphicon glyphicon-chevron-up"></i>
                    </a>
                  ) : null}
                  {index < numberOfBoxes - 1 ? (
                    <a
                      onClick={(e) => {
                        handleScrollStop(e);
                      }}
                      href={`#scrollstop${index + 1}`}
                      className="scroll-link link-down"
                    >
                      <i className="glyphicon glyphicon-chevron-down"></i>
                    </a>
                  ) : null}
                </div>
              </div>
            </section>
          </div>
        );
      })}
    </div>
  );
}
