import React, { useContext, useEffect, useRef, useState } from "react";
import Quill from "quill";
const Delta = Quill.import("delta");
import "../css/editor.css";
import { AuthContext } from "../state/auth";

import { DOMAttributes } from "react";
type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;
declare global {
  namespace JSX {
    interface IntrinsicElements {
      ["x-editable"]: CustomElement<any>;
    }
  }
}

export function initEditor(element: Element) {
  if (!element) {
    return;
  }
  const editor = new Quill(element, {
    modules: {
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "code-block"],
      ],
    },
    theme: "bubble",
  });

  return editor;
}

function hydrateEditorData(
  containerRef: React.MutableRefObject<Element | null>,
  editorRef: React.MutableRefObject<Quill | undefined>,
  pageData: Record<string, object>,
  uniqueDataKey: string
) {
  if (containerRef.current) {
    editorRef.current = initEditor(containerRef.current);
    editorRef.current?.disable();
    if (pageData[uniqueDataKey]) {
      editorRef.current?.setContents(new Delta(pageData[uniqueDataKey]));
    }
  }
}

type EditableProps = {
  uniqueDataKey: string;
  onSave: (uniqueDataKey: string, changes: typeof Delta) => void;
  children: React.ReactNode;
  pageDataAsync: () => Promise<any>;
};

export function Editable({
  uniqueDataKey,
  onSave,
  children,
  pageDataAsync,
}: EditableProps) {
  const [canEdit, setCanEdit] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const containerRef = useRef<Element | null>(null);
  const editorRef = useRef<Quill | undefined>();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.status === "signedin" && authContext.user) {
      setCanEdit(true);
      return;
    }
    setCanEdit(false);
  }, [authContext]);

  useEffect(() => {
    if (!editorRef.current) {
      pageDataAsync().then((pageData) => {
        hydrateEditorData(containerRef, editorRef, pageData, uniqueDataKey);
        setIsLoading(false);
      });
    }
    if (isEditing && editorRef.current) {
      editorRef.current.enable();
    }
    if (!isEditing && editorRef.current) {
      editorRef.current.disable();
    }
  }, [containerRef, isEditing, editorRef]);

  return (
    <div className={`editor-wrap ${isLoading ? "editor-is-loading" : ""}`}>
      {canEdit && (
        <button
          className="edit-button"
          onClick={() => {
            if (isEditing && editorRef.current) {
              onSave(uniqueDataKey, editorRef.current.getContents());
            }
            setIsEditing(!isEditing);
          }}
        >
          {isEditing ? "Done" : "Edit"}
        </button>
      )}
      <x-editable
        id={uniqueDataKey}
        ref={(ref: HTMLElement) => {
          containerRef.current = ref;
        }}
      >
        {children}
      </x-editable>
    </div>
  );
}
