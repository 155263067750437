import React from 'react';

export default function Errorpage() {
  return (
    <div
      id="errorPage"
      className="main-wrapper add-floating-bg add-floating-bg-1"
      data-editor-tags="new,dashboard"
    >
      <div className="container see-through">
        <h1 className="giant-header text-center header-404">
          <strong className="giant-header">404</strong>
          Sorry, Page Not Found
        </h1>
      </div>
    </div>
  );
}
