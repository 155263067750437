import React from 'react';
import Link from '../components/Link';

export default function LinksPage(props) {
  return (
    <div
      id="linksPage"
      className="main-wrapper add-floating-bg add-floating-bg-2"
      data-editor-tags="new,dashboard"
    >
      <article className="container see-through main-padding">
        <header>
          <h1 className="text-center">{props.title}</h1>
        </header>
        <section className="deep-inset-content clearfix">
          <ul className="list-unstyled text-center social-list">
            <li>
              <Link
                className="btn bubble-btn btn-lg btn-info social-bubble flip-btn"
                href="https://github.com/keeganbrown"
              >
                <span>
                  <i className="fa fa-github"></i>GitHub
                </span>
              </Link>
            </li>
            <li>
              <Link
                className="btn bubble-btn btn-lg btn-info social-bubble flip-btn"
                href="http://codepen.io/keeganbrown/"
              >
                <span>
                  <i className="fa fa-codepen"></i>CodePen
                </span>
              </Link>
            </li>
            <li>
              <Link
                className="btn bubble-btn btn-lg btn-info social-bubble flip-btn"
                href="https://www.linkedin.com/in/keegangbrown/"
              >
                <span>
                  <i className="fa fa-linkedin"></i>LinkedIn
                </span>
              </Link>
            </li>
            <li>
              <Link
                className="btn bubble-btn btn-lg btn-info social-bubble flip-btn"
                href="https://twitter.com/keegangbrown/"
              >
                <span>
                  <i className="fa fa-twitter"></i>Twitter
                </span>
              </Link>
            </li>
          </ul>
        </section>
      </article>
    </div>
  );
}
