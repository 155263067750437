import React, { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import ROUTES from "../config/routes";

const MainNav = (props) => {
  const [mobileMenu, setMobileMenu] = useState("closed");
  const onMenuClick = (event) => {
    mobileMenu === "in" ? setMobileMenu("closed") : setMobileMenu("in");
  };

  return (
    <div className="navbar row navbar-default navbar-fixed-top page-nav-wrapper">
      <div className="container nav-container">
        <div className="navbar-header pages-nav">
          <button
            onClick={onMenuClick}
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#main-nav-bar"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <small className="menu-text">menu</small>
          </button>
          <h2 className="navbar-brand">
            <NavLink to={ROUTES.ROOT}>
              <span>Mind &amp; Code</span>
              <small>
                Digital laboratory of Keegan Brown, software engineer.
              </small>
            </NavLink>
          </h2>
        </div>
        <nav
          id="main-nav-bar"
          className={`pages-nav navbar-right collapse navbar-collapse ${mobileMenu}`}
        >
          <ul className="inner nav navbar-nav flip-btns">
            <li>
              <NavLink onClick={onMenuClick} to={ROUTES.ROOT}>
                <span>Home</span>
              </NavLink>
            </li>
            {/* <li><NavLink activeClassName="active" to={ROUTES.BLOG.ROOT}><span>Lab</span></NavLink></li> */}
            <li>
              <NavLink onClick={onMenuClick} to={ROUTES.IRL}>
                <span>In Real Life</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onMenuClick} to={ROUTES.ONLINE}>
                <span>Online</span>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={onMenuClick} to={ROUTES.CV}>
                <span>Curriculum Vitae</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MainNav;
